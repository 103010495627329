import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { ContentfulEntry } from "frontend/contentful/schema/sys";
import { PageSectionThemeEntry } from "frontend/contentful/schema/blocks";
import {
  ArchivePageEntry,
  ArticlePageEntry,
  DetailPageEntry,
  KnowledgeBaseArticlePageEntry,
  LandingPageEntry,
  LandingPageBlockEntry,
  ArchivePageBlockEntry,
  PreFooterBlockEntry,
  DatabasePageEntry,
  DatasetPageEntry,
} from "frontend/contentful/schema/pages";
import { ArticlePageSettingsEntry } from "frontend/contentful/schema/universal";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import { PageSection } from "design-system/components/layouts/page-section/page-section";
import { RenderContentfulEntry } from "../utils/render-contentful-entry";
import { SimpleTheme } from "design-system/utils/theme";
import { useContentful } from "frontend/hooks/use-contentful";
import { ReactNode } from "react";

interface PageSectionBlocksProps {
  theme?: SimpleTheme;
  blocks: Array<ContentfulEntry>;
}

type PageSectionEntries =
  | LandingPageEntry
  | KnowledgeBaseArticlePageEntry
  | ArchivePageEntry
  | ArticlePageEntry
  | DetailPageEntry
  | ArticlePageSettingsEntry
  | DatabasePageEntry
  | DatasetPageEntry;

interface Options {
  key: "topContent" | "preFooterContent";
}

export const ContentfulPageSections = createContentfulComponent<
  PageSectionEntries,
  Options
>(({ entry, options }) => {
  const { getEntries } = useContentful();

  const sections: Array<PageSectionBlocksProps> = [];

  const isKnowledgeBaseArticlePage =
    isContentType<KnowledgeBaseArticlePageEntry>(
      "knowledgeBaseArticlePage",
      entry,
    );
  const isArchivePage = isContentType<ArchivePageEntry>("archivePage", entry);
  const isArticlePage = isContentType<ArticlePageEntry>("articlePage", entry);
  const isDetailPage = isContentType<DetailPageEntry>("detailPage", entry);
  const isDatabasePage = isContentType<DatabasePageEntry>(
    "databasePage",
    entry,
  );
  const isDatasetPage = isContentType<DatasetPageEntry>("datasetPage", entry);
  const isArticlePageSettings = isContentType<ArticlePageSettingsEntry>(
    "articlePageSettings",
    entry,
  );

  let blocks:
    | Array<LandingPageBlockEntry>
    | Array<ArchivePageBlockEntry>
    | Array<PreFooterBlockEntry>;

  if (isKnowledgeBaseArticlePage) {
    blocks = getEntries(entry.fields.body);
  } else if (isArchivePage) {
    if (options?.key) {
      blocks = getEntries(entry.fields[options.key]);
    } else {
      blocks = [];
    }
  } else if (
    isArticlePage ||
    isDetailPage ||
    isDatabasePage ||
    isDatasetPage ||
    isArticlePageSettings
  ) {
    blocks = getEntries(entry.fields.preFooterContent);
  } else {
    blocks = getEntries(entry.fields.blocks);
  }

  blocks?.forEach((block) => {
    if (isContentType<PageSectionThemeEntry>("pageSectionTheme", block)) {
      sections.push({ theme: block.fields.theme, blocks: [] });
    } else {
      if (sections.length === 0) {
        sections.push({
          blocks: [block],
          theme: isKnowledgeBaseArticlePage ? "white" : undefined,
        });
      } else {
        sections[sections.length - 1]?.blocks.push(block);
      }
    }
  });

  return (
    <>
      {sections.map(({ theme, blocks }, i) => {
        if (blocks.length < 1) return null;

        const blockTotal: Array<ReactNode> = [];
        let headerAbove = false;

        blocks.forEach((block, i) => {
          const options = { headerAbove: headerAbove };
          blockTotal.push(
            <RenderContentfulEntry key={i} entry={block} options={options} />,
          );
          if (
            block.sys.contentType.sys.id === "pageSectionHeader" ||
            block.sys.contentType.sys.id === "sideBySideSectionIntro"
          ) {
            // As long as I'm in the same section and BG hasn't changed assume section header is the parent to this whole section
            headerAbove = true;
          }
        });

        return (
          <PageSection key={i} theme={theme}>
            {blockTotal}
          </PageSection>
        );
      })}
    </>
  );
});
