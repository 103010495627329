import "./section-header.scss";

import cn from "clsx";
import {
  CtaLink,
  CtaLinkProps,
} from "design-system/components/primitives/cta-link/cta-link";
import { EditAttributes } from "design-system/types/types";
import { ReactNode } from "react";

export interface SectionHeaderProps {
  title?: string;
  subTitle?: string;
  description?: ReactNode;
  cta?: CtaLinkProps;

  editAttributes?: {
    title?: EditAttributes;
    subTitle?: EditAttributes;
    description?: EditAttributes;
  };
}

const LONG_HEADER_LENGTH = 50;

export function SectionHeader({
  title,
  subTitle,
  description,
  cta,
  editAttributes,
}: SectionHeaderProps) {
  const longHeader =
    (title && title.length > LONG_HEADER_LENGTH) ||
    (subTitle && subTitle.length > LONG_HEADER_LENGTH);

  const hgroupClass = cn(
    "hbs-section-header__hgroup",
    longHeader ? "hbs-section-header__hgroup--long" : "",
  );
  const showDesc = description || cta;

  return (
    <div className="hbs-global-align-full hbs-component--section-header">
      <div className="hbs-section-header">
        <div className="hbs-section-header-inner">
          <div className={hgroupClass}>
            {title && (
              <h2
                className="hbs-section-header__title"
                {...editAttributes?.title}
              >
                {title}
              </h2>
            )}
            {subTitle && (
              <p
                className="hbs-section-header__subtitle"
                {...editAttributes?.subTitle}
              >
                {subTitle}
              </p>
            )}
          </div>
          {showDesc && (
            <div
              className={cn(
                "hbs-section-header__dek",
                !description && "hbs-section-header__dek--no-desc",
              )}
            >
              <div {...editAttributes?.description}>
                {description}
                {cta && <CtaLink {...cta} isSmall={true} />}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
