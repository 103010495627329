import "./page-section.scss";

import cn from "clsx";

import { ReactNode } from "react";
import {
  SimpleTheme,
  defaultPageSectionTheme,
} from "design-system/utils/theme";
import {
  SectionHeader,
  SectionHeaderProps,
} from "design-system/components/blocks/section-header/section-header";

export interface PageSectionProps {
  children?: ReactNode;
  theme?: SimpleTheme;
  spacing?: string;
  header?: SectionHeaderProps;
}

/**
 * Standard themeable page section
 *
 * - **`id: P-019-000-00`**
 * - **`data-region: p__page-section`**
 */
export function PageSection({
  children,
  theme = defaultPageSectionTheme,
  spacing = "both",
  header,
}: PageSectionProps) {
  const sectionClass = cn(
    "hbs-page-section",
    spacing !== "both" && `hbs-page-section--${spacing}`,
  );

  return (
    <section
      className={sectionClass}
      data-theme={theme}
      data-region="p__page-section"
    >
      <div className="hbs-page-section__width">
        <div className="hbs-page-section__content">
          {header && <SectionHeader {...header} />}

          {children}
        </div>
      </div>
    </section>
  );
}
